<!--
 * @Author: your name
 * @Date: 2021-04-25 13:54:21
 * @LastEditTime: 2021-05-25 14:01:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\profilesetup\inswx.vue
-->
<template>
  <div class="allmsgclass">
    <navigation
      ><div @click="show = true">
        {{
          peoplemsg.status == 3 ? "入职" : peoplemsg.status == 2 ? "离职" : ""
        }}设置
      </div></navigation
    >
    <div class="themsgclass">
      <div class="topmsg">
        <div class="msg1">
          <div class="nayuan">
            <div>{{ peoplemsg.staff_name }}</div>
            <div>{{ peoplemsg.position ? peoplemsg.position : "暂无" }}</div>
            <img
              v-if="(peoplemsg.sex + '').indexOf('男') != -1"
              src="../../assets/sex_1.png"
              alt=""
            />
            <img
              v-if="(peoplemsg.sex + '').indexOf('女') != -1"
              src="../../assets/sex_2.png"
              alt=""
            />
          </div>
          <div class="phmsg">
            <span>{{
              peoplemsg.survey == 1
                ? "未背调"
                : peoplemsg.survey == 2
                ? "背调中"
                : peoplemsg.survey == 3
                ? "被拒绝"
                : peoplemsg.survey == 4
                ? "已背调"
                : peoplemsg.survey == 7
                ? "待支付"
                : "获取失败"
            }}</span>
            <span
              >{{
                peoplemsg.status == 1
                  ? "未入职"
                  : peoplemsg.status == 2
                  ? "已入职"
                  : peoplemsg.status == 3
                  ? "已离职"
                  : peoplemsg.status == 4
                  ? "拒绝入职"
                  : peoplemsg.status == 5
                  ? "离职确认中"
                  : peoplemsg.status == 6
                  ? "入职确认中"
                  : peoplemsg.status == 7
                  ? "拒绝离职"
                  : "获取失败"
              }}
            </span>
            <span>手机: {{ peoplemsg.mobile }}</span>
          </div>
        </div>
        <div class="imgdiv">
          <img
            v-if="(peoplemsg.sex + '').indexOf('男') != -1"
            src="../../assets/user_1.png"
            alt=""
          />
          <img
            v-if="(peoplemsg.sex + '').indexOf('女') != -1"
            src="../../assets/user_2.png"
            alt=""
          />
        </div>
      </div>
      <div class="zymenu">
        <div
          @click="menumeth(0)"
          :class="menuindex == 0 ? 'seleclass' : 'seleclass2'"
        >
          个人信息
        </div>
        <div
          @click="menumeth(1)"
          :class="menuindex == 1 ? 'seleclass' : 'seleclass2'"
        >
          职业档案
        </div>
        <div
          @click="menumeth(2)"
          :class="menuindex == 2 ? 'seleclass' : 'seleclass2'"
        >
          技能云
        </div>
      </div>
      <div v-show="menuindex == 0">
        <div class="ss3">
          <div class="ss1">
            <div></div>
            <div class="ss6">
              {{
                peoplemsg.status == 1
                  ? "未入职"
                  : peoplemsg.status == 2
                  ? "已入职"
                  : peoplemsg.status == 3
                  ? "已离职"
                  : peoplemsg.status == 4
                  ? "拒绝入职"
                  : peoplemsg.status == 5
                  ? "离职确认中"
                  : peoplemsg.status == 6
                  ? "入职确认中"
                  : peoplemsg.status == 7
                  ? "拒绝离职"
                  : "获取失败"
              }}
            </div>
          </div>
          <div class="imgcc">
            <div>
              <img src="../../assets/mm1.png" alt="" />{{
                peoplemsg.years_working
                  ? peoplemsg.years_working + "年"
                  : "未填写"
              }}
            </div>
            <div>
              <img src="../../assets/mm2.png" alt="" />{{
                peoplemsg.education_background
                  ? peoplemsg.education_background
                  : "未填写"
              }}
            </div>
            <div><img src="../../assets/mm3.png" alt="" />{{ age }}</div>
          </div>
        </div>
        <div class="ss3">
          <div class="ss1">
            <div></div>
            <div class="ss5">身份证号</div>
          </div>
          <div class="ss4">
            {{ peoplemsg.id_no ? peoplemsg.id_no : "未填写" }}
          </div>
        </div>
        <div class="ss3">
          <div class="ss1">
            <div></div>
            <div class="ss5">手机号</div>
          </div>
          <div class="ss4">
            {{ peoplemsg.mobile ? peoplemsg.mobile : "未填写" }}
          </div>
        </div>
        <div class="ss3">
          <div class="ss1">
            <div></div>
            <div class="ss5">邮箱</div>
          </div>
          <div class="ss4">
            {{ peoplemsg.email ? peoplemsg.email : "未填写" }}
          </div>
        </div>
        <div class="ss3">
          <div class="ss1">
            <div></div>
            <div class="ss5">岗位</div>
          </div>
          <div class="ss4">{{ peoplemsg.jop ? peoplemsg.jop : "未填写" }}</div>
        </div>
        <div class="ss3">
          <div class="ss1">
            <div></div>
            <div class="ss5">居住所在地</div>
          </div>
          <div class="ss4">
            {{ peoplemsg.province == "" ? "未填写" : peoplemsg.province }}
            {{ peoplemsg.city }} {{ peoplemsg.district }}
          </div>
        </div>
        <div @click="resumesee" class="ss3">
          <div class="ss1 ss8">
            <div></div>
            <div class="ss5">个人简历</div>
            <div class="ss7"><van-icon size=".3rem" name="arrow" /></div>
          </div>
          <div class="ss4">{{ jlmsg }}</div>
        </div>
        <div @click="recordmeth" class="ss3">
          <div class="ss1 ss8">
            <div></div>
            <div class="ss5">背调报告</div>
            <div class="ss7"><van-icon size=".3rem" name="arrow" /></div>
          </div>
          <div class="ss4">
            {{ peoplemsg.survey == 4 ? "查看报告" : "暂无报告" }}
          </div>
        </div>
        <!-- <div>
          <div class="bomsg">
            <div>姓名：</div>
            <div>
              {{ peoplemsg.staff_name ? peoplemsg.staff_name : "未填写" }}
            </div>
          </div>
          <div class="bomsg">
            <div>身份证：</div>
            <div>{{ peoplemsg.id_no ? peoplemsg.id_no : "未填写" }}</div>
          </div>
          <div class="bomsg">
            <div>手机号：</div>
            <div>{{ peoplemsg.mobile ? peoplemsg.mobile : "未填写" }}</div>
          </div>
          <div class="bomsg">
            <div>电子邮箱：</div>
            <div>{{ peoplemsg.email ? peoplemsg.email : "未填写" }}</div>
          </div>
          <div class="bomsg">
            <div>职位：</div>
            <div>{{ peoplemsg.position ? peoplemsg.position : "未填写" }}</div>
          </div>
          <div class="bomsg">
            <div>入职时间：</div>
            <div>
              {{ peoplemsg.entry_time && peoplemsg.entry_time.split(" ")[0] }}
            </div>
          </div>
          <div class="bomsg">
            <div>家庭住址：</div>
            <div>{{ peoplemsg.province ? peoplemsg.province : "未填写" }}</div>
          </div>
          <div class="bomsg">
            <div>具体地址：</div>
            <div>{{ peoplemsg.address ? peoplemsg.address : "未填写" }}</div>
          </div>
        </div> -->
        <!-- <div class="bbmsg">
          <div>个人简历</div>
          <div @click="resumesee">{{ jlmsg }}</div>
        </div>
        <div class="bbmsg">
          <div>背调报告</div>
          <div @click="recordmeth">
            {{ peoplemsg.survey == 4 ? "查看报告" : "暂无报告" }}
          </div>
        </div> -->
      </div>
      <div v-show="menuindex == 1">
        <div class="pinmsg">
          <div
            @click="pingmeth(0)"
            :class="pingindex == 0 ? 'pin1 pin3' : 'pin1'"
          >
            <div>
              <div>本公司</div>
              <div>
                <span>{{ evalution.length ? evalution.length : "0" }}</span
                >条
              </div>
            </div>
            <div class="xian1"></div>
            <div class="tt1">
              <div>评论数</div>
              <div class="tt2">
                <span>{{ allpinglun1 }}</span
                >条
              </div>
            </div>

            <img src="../../assets/zz1_1.png" alt="" />
          </div>
          <div
            @click="pingmeth(1)"
            :class="pingindex == 1 ? 'pin2 pin4' : 'pin2'"
          >
            <div>
              <div>其他企业</div>
              <div>
                <span>{{
                  otherpingjia.length ? otherpingjia.length : "0"
                }}</span
                >条
              </div>
            </div>
            <div class="xian1"></div>
            <div class="tt1">
              <div>评论数</div>
              <div class="tt2">
                <span>{{ allpinglun2 }}</span
                >条
              </div>
            </div>
            <img src="../../assets/zz2_2.png" alt="" />
          </div>
        </div>
        <div v-show="pingindex == 0">
          <div
            v-for="(item, index) in evalution"
            v-bind:key="index"
            class="pingjiaclassmsg"
          >
            <div @click="zhuangtaimeth(index)" class="pingjiacla">
              <div class="pingjiagongsi">评价公司：{{ item.company }}</div>
              <div class="qq2">
                {{ zhuangtai == index ? "收起" : "展开" }}
              </div>
            </div>
            <div :class="zhuangtai == index ? ' height1' : 'height2'">
              <div class="pingjia2cla">
                <!-- <div class="pinclatwo">
                  <div class="asc">
                    <span>评价人：</span
                    ><span class="naclass">{{ create.username }}</span>
                  </div>
                  <div>
                    <span>评价人职务：</span
                    ><span class="naclass">{{ create.position }}</span>
                  </div>
                </div> -->
                <div class="pinclatwo">
                  <div>评价人：{{ create.username }}</div>
                </div>
                <div class="pinclatwo">
                  <div>评价人职务：{{ create.position }}</div>
                </div>
                <div class="pinclatwo">
                  <div>被评价人：{{ item.staff_name }}</div>
                </div>
                <div class="pinclatwo">
                  <div>被评价人职位：{{ item.position }}</div>
                </div>
                <div class="pinclatwo">
                  <div>创建时间：{{ item.add_time.split(" ")[0] }}</div>
                </div>
                <!-- <div class="pinclatwo">
                  <div>评价标签：工作表现</div>
                </div> -->
                <div class="pinclatwo">
                  <div class="pjwz">
                    <div>评价文字：</div>
                    <div>{{ item.content }}</div>
                  </div>
                </div>
              </div>
              <div class="ckp_1">
                <div class="ckp2">
                  <van-icon
                    @click="evalseemeth(item)"
                    size=".5rem"
                    color="#979797"
                    name="chat-o"
                  />
                  <div>{{ item.myCommentCount }}</div>
                </div>

                <div @click="evalseemeth(item)">查看评论</div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="pingindex == 1">
          <div
            v-for="(item, index) in otherpingjia"
            v-bind:key="index"
            class="pingjiaclassmsg"
          >
            <div @click="zhuangtaimeth(index)" class="pingjiacla pingjiacla2">
              <div class="qq3">评价公司</div>
              <div class="qq1">
                <div class="pingjiagongsi qq3">{{ item.company }}</div>
                <div class="qq2">
                  {{ zhuangtai == index ? "收起" : "展开" }}
                </div>
              </div>
              <div class="qq4">评价数：{{ item.list.length }}</div>
            </div>
            <div :class="zhuangtai == index ? ' height1' : 'height2'">
              <div v-for="(itemes, indexes) in item.list" v-bind:key="indexes">
                <div class="pingjia3cla">
                  <!-- <div class="pinclatwo">
                    <div class="asc">
                      <span>评价人：</span
                      ><span class="naclass">{{ itemes.e_name }}</span>
                    </div>
                    <div>
                      <span>评价人职务：</span><span class="naclass">保密</span>
                    </div>
                  </div> -->
                  <div class="pinclatwo">
                    <div>评价人：{{ itemes.e_name }}</div>
                  </div>
                  <div class="pinclatwo">
                    <div>评价人职务：{{ itemes.post }}</div>
                  </div>
                  <div class="pinclatwo">
                    <div>被评价人：{{ itemes.staff_name }}</div>
                  </div>
                  <div class="pinclatwo">
                    <div>被评价人职位：{{ itemes.position }}</div>
                  </div>
                  <div class="pinclatwo">
                    <div>创建时间：{{ itemes.add_time.split(" ")[0] }}</div>
                  </div>
                  <!-- <div class="pinclatwo">
                  <div>评价标签：工作表现</div>
                </div> -->
                  <div class="pinclatwo">
                    <div class="pjwz">
                      <div>评价文字：</div>
                      <div>{{ itemes.content }}</div>
                    </div>
                  </div>
                </div>
                <div class="ckp">
                  <div class="ckp2">
                    <van-icon
                      @click="evalseemeth(itemes, true)"
                      size=".5rem"
                      color="#979797"
                      name="chat-o"
                    />
                    <div>{{ itemes.myCommentCount }}</div>
                  </div>

                  <div @click="evalseemeth(itemes, true)">查看评论</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="menuindex == 2 && ishavemsg == true">
        <skilllistcom :phone="peoplemsg.mobile"></skilllistcom>
      </div>
    </div>
    <div class="buttonclass">
      <button @click="toeva" class="bu1">评价</button>
      <button @click="clickok" class="bu2">发起背调</button>
    </div>
    <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      description="状态设置"
      close-on-click-action
      @select="selectmeth"
    />

    <div class="nomsgclass"></div>
    <sharepage></sharepage>
  </div>
</template>
<script>
import sharepage from "../../views/sharepage/index";
import getage from "../../../util/age";
import skilllistcom from "../../components/skilllistcom";
export default {
  components: {
    skilllistcom,
    sharepage,
  },
  data() {
    return {
      menuindex: 0,
      show: false,
      actions: [],
      zhuangtai: "-1", //是否是展开
      evalution: "", //本公司评价
      sumnum: "", //其他公司评价数量
      create: "", //评价人
      otherpingjia: "", //其他公司评价
      pingindex: 0,
      peoplemsg: "",
      report: "", //报告信息
      ispay: "", //是否购买过其他公司评价
      jlmsg: "查看简历",
      age: "",
      ishavemsg: false,
      allpinglun1: "", //评论数（本企业）
      allpinglun2: "", //评论数（其他企业）
    };
  },
  watch: {
    pingindex(oldmsg) {
      this.zhuangtai = "-1";
      if (oldmsg == 1) {
        //获取其他公司评价
        this.$http
          .post("/firm/v1/profile/getcompanyevaluation", {
            reqType: "profile",
            score: "",
            sid: this.peoplemsg.id,
            start_time: "",
            end_time: "",
            article: 9999999,
            page: 1,
          })
          .then((res) => {
            this.otherpingjia = JSON.parse(res.data).data.list.data;
          });
      }
    },
  },
  created() {
    let sharemm = JSON.parse(sessionStorage.getItem("sharemsg"));
    if (sharemm != "" && sharemm != undefined && sharemm.urlsharees) {
      this.$route.query.id = btoa(sharemm.id);
      this.$route.query.l_id = btoa(sharemm.l_id);
    }
    this.tosharemeth();
  },
  mounted() {
    if (sessionStorage.getItem("profilemsg_menuindex")) {
      this.menuindex = sessionStorage.getItem("profilemsg_menuindex");
    }
    this.seeyuanmsg();
    this.seepingmsg();
  },
  methods: {
    //分享内容
    tosharemeth() {
      let obj = JSON.stringify({
        sharetitle: "0成本员工激励神器",
        sharedesc: "做对这件事，员工每天干劲十足！",
        isshare: "1",
        share_phone: localStorage.getItem("phone"),
        id: atob(this.$route.query.id),
        l_id: atob(this.$route.query.l_id),
      });
      localStorage.setItem("shareobj", obj);
    },
    //其他公司评价数量
    getother() {
      //获取其他公司评价
      this.$http
        .post("/firm/v1/profile/getcompanyevaluation", {
          reqType: "profile",
          score: "",
          sid: this.peoplemsg.id,
          start_time: "",
          end_time: "",
          article: 9999999,
          page: 1,
        })
        .then((res) => {
          this.otherpingjia = JSON.parse(res.data).data.list.data;
          this.allpinglun2 = 0;
          let themsg = this.otherpingjia;
          themsg.forEach((e) => {
            e.list.forEach((p) => {
              this.allpinglun2 += p.myCommentCount;
            });
          });
        });
    },
    selectmeth(msg) {
      if (msg.status == "") {
        this.$toast("状态确认中");
        return;
      }
      this.rulizhi(msg.status);
    },
    //入离职
    rulizhi(status) {
      this.$http
        .post("/firm/v1/profile/status", {
          reqType: "profile",
          status: status, //2:入职 3：离职
          id: this.peoplemsg.id,
        })
        .then((res) => {
          this.$toast(JSON.parse(res.data).msg);
          this.$router.go(0);
        });
    },
    //查看评价信息
    seepingmsg() {
      this.$http
        .post("/firm/v1/profile/evalutionTag", {
          reqType: "profile",
        })
        .then((res) => {});
    },
    //查看员工信息
    seeyuanmsg() {
      this.$http
        .post("/firm/v1/profile/details", {
          reqType: "profile",
          id: this.$route.query.id && atob(this.$route.query.id),
          l_id: this.$route.query.l_id && atob(this.$route.query.l_id),
        })
        .then((res) => {
          //员工信息
          this.peoplemsg = JSON.parse(res.data).data.profile;
          //本公司评价
          this.evalution = JSON.parse(res.data).data.evalution;
          //其他公司评价
          this.sumnum = JSON.parse(res.data).data.sum.num;
          //评价人
          this.create = JSON.parse(res.data).data.create;
          //报告信息
          this.report = JSON.parse(res.data).data.report;
          //是否购买过其他公司评价
          this.ispay = JSON.parse(res.data).data.isPay;

          this.ishavemsg = true;

          //本公司评价数
          this.allpinglun1 = 0;
          this.evalution.forEach((e) => {
            this.allpinglun1 += e.myCommentCount;
          });

          if (!this.peoplemsg.request_report_id) {
            this.jlmsg = "暂无简历";
          }

          if (this.peoplemsg.status == 3) {
            this.actions = [{ name: "入职", status: 2 }];
          } else if (this.peoplemsg.status == 2) {
            this.actions = [{ name: "离职", status: 3 }];
          } else {
            this.actions = [
              {
                name:
                  this.peoplemsg.status == 5
                    ? "离职确认中"
                    : this.peoplemsg.status == 6
                    ? "入职确认中"
                    : "",
                status: "",
              },
            ];
          }
          //年龄
          this.age = getage(this.peoplemsg.birthday, this.peoplemsg.age);
          this.getother();
        });
    },

    recordmeth() {
      if (this.peoplemsg.survey != 4) {
        this.$toast("暂无背调记录");
        return;
      }
      localStorage.setItem("z_id", this.report.z_id);
      localStorage.setItem("r_id", this.report.request_report_id);
      this.$router.push({
        path: "/reportview2",
        query: {},
      });
    },
    resumesee() {
      if (!this.peoplemsg.request_report_id) {
        this.$toast("暂无简历");
        this.jlmsg = "暂无简历";
        return;
      }
      if (
        this.peoplemsg.status == 1 ||
        this.peoplemsg.status == 6 ||
        this.peoplemsg.status == 3
      ) {
        this.$toast("请先完成入职，即可开启员工档案和技能云");
        return;
      }
      this.$router.push({
        path: "/resumesee",
        query: {
          id: btoa(this.peoplemsg.request_report_id),
          userid: btoa(this.peoplemsg.id),
        },
      });
    },
    pingmeth(index) {
      if (index == 1) {
        if (!this.sumnum) {
          this.$toast("暂无其他公司评价");
          return;
        }
        if (this.ispay == 1) {
          this.pingindex = index;
        } else {
          this.$router.push({
            path: "evalpay",
            query: {
              msg: JSON.stringify({
                ispingjia: "true",
                id: this.peoplemsg.id,
                tipmsg: "支付查看评价",
              }),
            },
          });
        }
      } else {
        this.pingindex = index;
      }
    },
    //点击展开
    zhuangtaimeth(index) {
      this.zhuangtai == index
        ? (this.zhuangtai = "-1")
        : (this.zhuangtai = index);
    },
    menumeth(index) {
      if (index == 2) {
        if (
          this.peoplemsg.status == 1 ||
          this.peoplemsg.status == 6 ||
          this.peoplemsg.status == 3
        ) {
          this.$toast("请先完成入职，即可开启员工档案和技能云");
          return;
        }
      }
      this.menuindex = index;
      sessionStorage.setItem("profilemsg_menuindex", index);
    },
    toeva() {
      if (this.peoplemsg.status != 2) {
        this.$toast("该员工未入职");
        return;
      }
      this.$router.push({
        path: "/evaluation",
        query: {
          sid: btoa(this.peoplemsg.id),
          user_id: btoa(this.peoplemsg.request_report_id),
          status: btoa(this.peoplemsg.status),
          phone: btoa(this.peoplemsg.mobile),
        },
      });
    },
    clickok() {
      sessionStorage.setItem("sub2msgMobile", this.peoplemsg.mobile);
      sessionStorage.setItem("sub2msgName", this.peoplemsg.staff_name);
      sessionStorage.setItem("sub2msgid", this.peoplemsg.id);
      if (this.peoplemsg.id_no) {
        sessionStorage.setItem("sub2msgId_no", this.peoplemsg.id_no);
      }
      this.$router.push("/backtotwo");
    },
    evalseemeth(item) {
      if (
        this.peoplemsg.status == 1 ||
        this.peoplemsg.status == 6 ||
        this.peoplemsg.status == 3
      ) {
        this.$toast("请先完成入职，即可开启员工档案和技能云");
        return;
      }

      this.$router.push({
        path: "/evalsee",
        query: {
          id: btoa(item.id),
          phone: btoa(item.mobile),
          iscom: arguments[1] && arguments[1],
          company: item.company,
        },
      });
    },
  },
  destroyed() {
    sessionStorage.removeItem("sharemsg");
  },
};
</script>
<style scoped lang=scss>
.qq4 {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.09rem 0.2rem;
  background-color: #ff6420;
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #ffffff;
  border-bottom-left-radius: 0.3rem;
}
.qq3 {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #222222;
}
.qq1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.xian1 {
  height: 1px;
  background-color: #c1edff;
  margin: 0.19rem 0;
}
.tt1 {
  position: relative;
}
.tt2 {
  position: absolute;
  right: 0;
  bottom: -0.05rem;
}
.ss8 {
  position: relative;
}
.ss7 {
  position: absolute;
  right: 0;
  bottom: -0.3rem;
}
.ss6 {
  font-size: 0.3rem;
  line-height: 0.48rem;
  color: #333333;
  font-weight: bold;
}
.ss5 {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #313131;
}
.ss4 {
  margin-left: 0.28rem;
  font-weight: bold;
  font-size: 0.3rem;
  line-height: 0.48rem;
}
.ss3 {
  padding-bottom: 0.36rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d6d6d6;
  margin-bottom: 0.4rem;
}
.imgcc {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  line-height: 0.48rem;
  color: #6f6f6f;
  margin-left: 0.3rem;
}
.imgcc > div {
  margin-right: 0.5rem;
}
.imgcc > div:nth-child(3) {
  color: #313131;
}
.imgcc img {
  width: 0.3rem;
  margin-right: 0.17rem;
}
.ss1 {
  display: flex;
  align-items: center;
  margin-bottom: 0.29rem;
}
.ss1 > :first-child {
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 50%;
  background-color: #ff4900;
  margin-right: 0.17rem;
}

.ckp2 {
  display: flex;
  align-items: center;
}
.ckp2 > :nth-child(2) {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #6f6f6f;
  margin-left: 0.12rem;
}
.pin3 {
  position: relative;
}
.pin3::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 0.2rem solid #eaf6fd;
  position: absolute;
  bottom: -0.35rem;
  left: 50%;
  transform: translateX(-50%);
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.pin4::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 0.2rem solid #fff6f2;
  position: absolute;
  bottom: -0.35rem;
  left: 50%;
  transform: translateX(-50%);
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.pingjiagongsi {
  width: 5.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.height1 {
  height: 6.4rem;
  transition: height 0.3s;
  overflow: auto;
}
.height2 {
  height: 0;
  overflow: hidden;
}
.pingjiaclassmsg2 {
  margin-bottom: -0.5rem;
}

.ckp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.22rem 0.35rem;
  height: 0.5rem;
  background-color: #ffffff;
  z-index: 999;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #dfdfdf;
  margin-bottom: 0.5rem;
}
.ckp_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.22rem 0.35rem;
  /* height: 0.5rem; */
  background-color: #ffffff;
  z-index: 999;
}
.pingjia2cla {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #6f6f6f;
  padding: 0 0.25rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #dfdfdf;
  padding-top: 0.38rem;
  height: 5rem;
  overflow: auto;
  background-color: #ffffff;
  z-index: 888;
}
.pingjia3cla {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #6f6f6f;
  padding: 0 0.25rem;
  padding-top: 0.38rem;
  height: 4.7rem;
  overflow: auto;
  background-color: #ffffff;
  z-index: 888;
}
.ckp > :nth-child(2) {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #ff4901;
  background-color: #ffffff;
}
.ckp_1 > :nth-child(2) {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #ff4901;
  background-color: #ffffff;
}
.pingjiaclassmsg {
  box-shadow: 0px 0px 0.1rem 0px rgba(0, 0, 0, 0.2);
}
.pjwz {
  display: flex;
}
.pjwz > :first-child {
  min-width: 1.6rem;
}
.pinclatwo {
  display: flex;
  align-items: center;
  margin-bottom: 0.43rem;
  background-color: #ffffff;
}
.asc {
  padding-right: 0.3rem;
  border-right-color: #6f6f6f;
  border-right-style: solid;
  border-right-width: 1px;
  margin-right: 0.3rem;
}
.naclass {
  color: #222222;
}

.pingjiacla {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.22rem 0.25rem;
  background-color: #ffede4;
  margin: 0.4rem 0;
  margin-bottom: 0;
}
.pingjiacla2 {
  display: block;
  position: relative;
}
.pingjiacla > :first-child {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #222222;
}
.qq2 {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #ff5f19;
}
.pinmsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pinmsg > div {
  padding: 0.32rem 0.43rem;
  border-radius: 0.18rem;

  padding-top: 0.4rem;
}
.pin1 {
  background-image: url("../../assets/zz1.png");
  background-size: 100% 100%;
  width: 35%;
  position: relative;
  padding: -0.3rem;
}
.pin1 img,
.pin2 img {
  position: absolute;
  right: 0.1rem;
  bottom: 0.1rem;
  width: 1.2rem;
  z-index: 1;
}
.pin1 > :first-child,
.pin2 > :first-child {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #333333;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  white-space: nowrap;
}
.pin1 > :first-child span,
.pin2 > :first-child span {
  font-size: 0.4rem;
  line-height: 0.56rem;
  color: #ff5f19;
  font-weight: bold;
  margin-right: 0.06rem;
}
.pin1 > :nth-child(3),
.pin2 > :nth-child(3) {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #333333;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  z-index: 2000;
}
.pin1 > :nth-child(3) span,
.pin2 > :nth-child(3) span {
  font-size: 0.4rem;
  line-height: 0.56rem;
  color: #ff5f19;
  font-weight: bold;
  margin-right: 0.06rem;
}
/* .pin1 > :nth-child(2) > :first-child {
  font-size: 0.6rem;
  line-height: 0.84rem;
  color: #ff5f19;
  font-weight: bold;
  margin-right: 0.02rem;
}
.pin1 > :nth-child(2) > :nth-child(2) {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #333333;
} */
/* .pin2 > :first-child {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #333333;
}
.pin2 > :nth-child(2) > :first-child {
  font-size: 0.6rem;
  line-height: 0.84rem;
  color: #ff5f19;
  font-weight: bold;
  margin-right: 0.02rem;
}
.pin2 > :nth-child(2) > :nth-child(2) {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #333333;
} */
.pin2 {
  background-image: url("../../assets/zz2.png");
  background-size: 100% 100%;
  width: 35%;
  position: relative;
}
.nomsgclass {
  height: 1.8rem;
}
.buttonclass {
  position: fixed;
  bottom: 0;
  padding: 0.28rem 5% 0.28rem 5%;
  width: 90%;
  box-shadow: 0 -0.05rem 0.1rem rgb(243, 241, 241);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}
.bu1 {
  font-size: 0.3rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.42rem;
  border-radius: 0.16rem;
  border: none;
  padding: 0.24rem 0.8rem;
  color: #ff5f19;
  background: #ffffff;
  border-radius: 0.16rem;
  border: 0.03rem solid #ff5f19;
}
.bu2 {
  font-size: 0.3rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  border-radius: 0.16rem;
  border: none;
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  padding: 0.27rem 1.5rem;
}
.bbmsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.31rem;
}
.bbmsg > :first-child {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #6f6f6f;
}
.bbmsg > :nth-child(2) {
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #ff5f19;
  padding: 0.1rem 0.24rem;
  border-radius: 0.08rem;
  border: 1px solid #ff5f19;
}

.zymenu {
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  line-height: 0.45rem;
  color: #797979;
  margin-top: 0.2rem;
  margin-bottom: 0.38rem;
}
.zymenu > :first-child,
.zymenu > :nth-child(2) {
  margin-right: 0.7rem;
}
.bomsg {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #6f6f6f;
  margin-bottom: 0.43rem;
}
.bomsg > :first-child {
  min-width: 2rem;
}
.themsgclass {
  margin: 0.33rem 0.38rem;
}
.phmsg {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #6f6f6f;
  margin-top: 0.07rem;
}
.phmsg > :nth-child(2) {
  margin: 0 0.1rem;
}
.msg1 {
  width: 100%;
}
.nayuan {
  display: flex;
  align-items: center;
}
.nayuan > :first-child {
  font-size: 0.46rem;
  line-height: 0.65rem;
  font-weight: bold;
  margin-right: 0.17rem;
}
.nayuan > :nth-child(2) {
  font-size: 0.18rem;
  line-height: 0.25rem;
  color: #ffffff;
  padding: 0.05rem 0.1rem;
  background-color: #00beff;
  border-radius: 0.08rem;
  margin-right: 0.16rem;
  max-width: 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.nayuan > img {
  width: 0.4rem;
  height: 0.4rem;
}
.topmsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom-color: #dfdfdf;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.imgdiv {
  width: 1.57rem;
  max-height: 1.57rem;
  border-radius: 100%;
  overflow: hidden;
}
.imgdiv > img {
  width: 100%;
  height: 100%;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
.seleclass {
  font-weight: bold;
  color: #ff5f19;
}
.seleclass::after {
  content: "";
  display: block;
  height: 3px;
  width: 0.6rem;
  background-color: #ff5f19;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 0.15rem;
  border-radius: 0.3px;
}
.seleclass2::after {
  content: "";
  display: block;
  height: 3px;
  width: 0.6rem;
  background-color: #ffffff;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 0.15rem;
  border-radius: 0.3px;
}
</style>