<template>
  <div class="allmsgclass">
    <div class="themsgclass2">
      <div class="jcla">
        <div>工作技能</div>
        <div v-if="skilllist[0]" @click="toaddskill(0)" class="jcla2">
          <van-icon name="add-o" size=".2rem" color="#ff4800" />
          <div>新增技能</div>
        </div>
      </div>
      <div class="skillclass">
        <div class="fff" v-if="workskill.length > 0">
          <div
            @click="toskillmsg(item, '工作技能')"
            :class="item.status==1?'skilldiv2':'skilldiv'"
            v-for="(item, index) in workskill"
            v-bind:key="index"
          >
            {{ item.skill_name }}.{{item.count}}
          </div>
        </div>
        <div v-else><nomsgvue></nomsgvue></div>
      </div>
      <div class="jcla">
        <div>生活技能</div>
        <div v-if="skilllist[1]" @click="toaddskill(1)" class="jcla2">
          <van-icon name="add-o" size=".2rem" color="#ff4800" />
          <div>新增技能</div>
        </div>
      </div>
      <div class="skillclass">
        <div class="fff" v-if="liftskill.length > 0">
          <div
            @click="toskillmsg(item, '生活技能')"
            :class="item.status==1?'skilldiv2':'skilldiv'"
            v-for="(item, index) in liftskill"
            v-bind:key="index"
          >
            {{ item.skill_name }}.{{item.count}}
          </div>
        </div>
        <div v-else><nomsgvue></nomsgvue></div>
      </div>
      <!-- <div class="jcla">
        <div>其他技能</div>
        <div v-if="skilllist[2]" @click="toaddskill(2)" class="jcla2">
          <van-icon name="add-o" size=".2rem" color="#ff4800" />
          <div>新增技能</div>
        </div>
      </div>
      <div class="skillclass">
        <div class="fff" v-if="otherskill.length > 0">
          <div
            @click="toskillmsg(item, '其他技能')"
            :class="item.count>0?'skilldiv2':'skilldiv'"
            v-for="(item, index) in otherskill"
            v-bind:key="index"
          >
            {{ item.skill_name }}
          </div>
        </div>
        <div v-else><nomsgvue></nomsgvue></div>
      </div> -->
      <div class="jcla">
        <div>综合技能</div>
        <div @click="toaddskill(3)" class="jcla2">
          <van-icon name="add-o" size=".2rem" color="#ff4800" />
          <div>新增技能</div>
        </div>
      </div>
      <div class="skillclass">
        <div class="fff" v-if="zdyskill.length > 0">
          <div
            @click="toskillmsg(item, '自定义技能')"
            :class="item.status==1?'skilldiv2':'skilldiv'"
            v-for="(item, index) in zdyskill"
            v-bind:key="index"
          >
            {{ item.position_name }}.{{item.count}}
          </div>
        </div>
        <div v-else><nomsgvue></nomsgvue></div>
      </div>
    </div>
  </div>
</template>
<script>
import nomsgvue from "./nomsg/index";
export default {
  props: {
    phone: Number,
  },
  components: {
    nomsgvue,
  },
  data() {
    return {
      workskill: [], //工作技能
      liftskill: [], //生活技能
      otherskill: [], //其他技能
      zdyskill: [], //自定义技能
      skilllist: "", //技能列表
    };
  },
  mounted() {
     
    this.getmsg();
    this.getzidingy();
    this.getskilllist();
  },
  methods: {
    toaddskill(index) {
      sessionStorage.setItem("profilemsg_msg", "true");
      if (index == 3) {
        this.$router.push({
          path: "/addskill2",
          query: {
            phone: btoa(this.phone),
          },
        });
      } else {
        this.$router.push({
          path: "/addskill3",
          query: {
            phone: btoa(this.phone),
            positionId: btoa(this.skilllist[index].positionId),
            position_name: this.skilllist[index].position_name,
          },
        });
      }
    },
    //获取技能列表
    getskilllist() {
      this.$http
        .post("/firm/v1/Skillme/positionList", {
          reqType: "userinfo",
          pid: "",
          name: "",
        })
        .then((res) => {
           
          this.skilllist = JSON.parse(res.data).data;
        });
    },
    //查看自定义技能
    getzidingy() {
      this.$http
        .post("/firm/v1/Skillme/getCustom", {
          reqType: "userinfo",
          phone: this.phone,
        })
        .then((res) => {
           
          this.zdyskill = JSON.parse(res.data).data.custom;
        });
    },
    //添加技能
    addskillmeth() {
      this.$router.push({
        path: "/addskill",
        query: {
          phone: btoa(this.phone),
        },
      });
    },
    toskillmsg(item, msg) {
       
       
      this.$router.push({
        path: "/skillmsg",
        query: {
          msg: JSON.stringify(item),
          skillmenu: msg,
        },
      });
    },
    getmsg() {
      this.$http
        .post("/firm/v1/Skillme/getSkill", {
          reqType: "userinfo",
          phone: this.phone,
        })
        .then((res) => {
           
          let allmsg = JSON.parse(res.data).data.skill_list;
          allmsg.forEach((e) => {
            if (e.skill_type == 1) {
              this.workskill.push(e);
            } else if (e.skill_type == 2) {
              this.liftskill.push(e);
            } 
          });
        });
    },
  },
};
</script>
<style scoped>
.skillclass {
  margin-top: 0.4rem;
  margin-bottom: 0.76rem;
}
.fff {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.skilldiv {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #222222;
  border: 1px solid #979797;
  padding: 0.2rem;
  border-radius: 0.4rem;
  margin-right: 0.2rem;
  margin-bottom: 0.23rem;
}
.skilldiv2 {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #ff5f19;
  border: 1px solid #ff5f19;
  padding: 0.2rem;
  border-radius: 0.4rem;
  margin-right: 0.2rem;
  margin-bottom: 0.23rem;
}
.jcla {
  font-size: 0.3rem;
  line-height: 0.48rem;
  font-weight: 500;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.jcla2 {
  display: flex;
  align-items: center;
  font-size: 0.2rem;
  margin-left: 0.3rem;
  font-weight: 500;
}
.jcla2 > :first-child {
  margin-right: 0.05rem;
}
.themsgclass2 {
  margin: 0.3rem 0;
}
.addclass > :first-child {
  margin-right: 0.14rem;
}
.addclass {
  display: flex;
  align-items: center;
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
</style>
